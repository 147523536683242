import React, {useState} from 'react';
import { useLocation } from 'react-router-dom';
import './tutordetails.scss'
import Input from '../../components/Input/Input';
import { useAuth } from '../../util/auth.js';
import axios from 'axios';
function TutorDetails() {

  const location = useLocation();
  const encodedTutorData = location.pathname.split('/').pop();
  const decodedTutorData = JSON.parse(decodeURIComponent(encodedTutorData));

  const [loading, setLoading] = useState(false);
  const [Time, setTime] = useState('');
  const [selectedDate, setSelectedDate] = useState([]);
  const [description, setDescription] = useState('');
  const { getUser, signOut, getAccessToken } = useAuth();

  const handleUnitChangeDate = (event) => {
    const value = event.target.value;
    setSelectedDate((prevSelectedDate) => {
      if (prevSelectedDate.includes(value)) {
        return prevSelectedDate.filter((date) => date !== value);
      } else {
        return [...prevSelectedDate, value];
      }
    });
  };
 async function submitTutoring(e){
  e.preventDefault();
  setLoading(true);
  var token = getAccessToken();
  try {
  await axios.post(`${process.env.REACT_APP_URL}api/schedule/sendRequest`, {
      token: token, 
      tutor: decodedTutorData.email,
      time: Time,
    })
  }catch (error) {
    console.error('Error submitting form', error);
  }
  setLoading(false);
  }

return (
    <div className='tutor-details'>
      <h1>{loading ? 'Loading...' : 'Tutor Details'}</h1>
      <p>Email: {decodedTutorData.email}</p>
      {decodedTutorData.role.map((role, index) => (
      <p><b>Subject: </b>{role + ": "+ decodedTutorData.roleUnits[index]}</p>
      ))}
      <form onSubmit={submitTutoring}>
        <br></br>
        <p>{decodedTutorData.time}</p>
        <br></br>
        <p>{decodedTutorData.description}</p>
     
        <Input label="time" type="text" data={Time} setData={setTime} />
        
        <Input label="Description" type="text" data={description} setData={setDescription} />
      <button type="submit" className='Btn'>Request An Appointment</button>
      </form>
    </div>
  );
}

export default TutorDetails;
