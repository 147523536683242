import '.././../App.css'
import './signin.scss';
import React, { useState } from 'react'
import { useAuth } from '../../util/auth.js';
import Input from '../../components/Input/Input';
function SignIn() {
	const [email, setEmail] = useState('')
	const [error, setError] = useState(null);
	const [password, setPassword] = useState('')
	const [loading, setLoading] = useState(false);
	const { signIn } = useAuth();

	async function handleSubmit(e) {
		e.preventDefault();
		setLoading(true);

		var data = {
			email: email,
			password: password
		}

		try {
			await signIn(data);
			window.location.href = '/User';	
		} catch (error) {
			setError(error.message)
		}

		setLoading(false);
	}

	return (
		<div className="signUpPage">
			<h1>{loading ? "Loading..." : "Sign In"}</h1>
			<form onSubmit={handleSubmit}>
				<Input label="Email" type="email" data={email} setData={setEmail} />
				<Input label="Password" type="password" data={password} setData={setPassword} />
				{error && <p className="error">{error}</p>}
				<p>Dont have an account? <a href="sign-up">Sign up</a></p>
				<button type="submit" disabled={loading}>Log In</button>
			</form>
		</div>
	)
}

export default SignIn;