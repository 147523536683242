import axios from 'axios';
import { createContext, useContext } from 'react';

const AuthContext = createContext();

export default function AuthProvider({ children }) {
	function saveTokens(accessToken, refreshToken) {
		localStorage.setItem('accessToken', accessToken);
		localStorage.setItem('refreshToken', refreshToken);
	}
	
	function removeTokens() {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
	}
	
	function getAccessToken() {
		return localStorage.getItem("accessToken")
	}
	
	function getRefreshToken() {
		return localStorage.getItem("refreshToken");
	}
	// eslint-disable-next-line no-unused-vars
	async function signUp(data) {
		console.log("hi");
		await axios.post(`${process.env.REACT_APP_URL}api/auth/create`, data)
			.then((response) => {
				saveTokens(response.data.accessToken, response.data.refreshToken);
				return response.data;
			})
			.catch((error) => {
		
			}
		);
	}

	// eslint-disable-next-line no-unused-vars
	async function signIn(data) {
		await axios.post(`${process.env.REACT_APP_URL}api/auth/login`, data)
			.then((response) => {
				 saveTokens(response.data.accessToken, response.data.refreshToken);
                                if(response.data.accessToken == null){
				alert(response.data);
				}
				return response.data;
			})
			.catch((error) => {
				alert(error);
			}
		);
	}

	async function getUser() {
		let token = getAccessToken();

		if (!token) return null;

		try {
			let user = JSON.parse(atob(token.split('.')[1]));

			if(user.exp < Date.now() / 1000) {
				token = await refreshAccessToken();

				if(!token) return null;

				user = JSON.parse(atob(token.split('.')[1]));
			}

			user.token = token;
			return user;
		} catch (error) {
			return null;
		}
	}

	async function signOut() {
		window.location.href = '/'
		const refreshToken = getRefreshToken();
		removeTokens();
		if (!refreshToken) return;

		await axios.post(`${process.env.REACT_APP_URL}api/auth/logout`, { token: refreshToken });
	}
	
	// Function to refresh access token
	async function refreshAccessToken() {
		const refreshToken = getRefreshToken();
		if (!refreshToken) return null;
	  
		try {
		
			const response = await axios.post(`${process.env.REACT_APP_URL}api/auth/token`, { token: refreshToken });
			
			saveTokens(response.data.accessToken, refreshToken);

			return response.data.accessToken;
		} catch (error) {
			console.error('Token Refresh Error:', error.message);
			return null;
		}
	}

	const value = {
		signUp,
		signIn,
		getUser,
		signOut,
		getAccessToken,
		refreshAccessToken
	}

	return (
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>
	)
}

export function useAuth() {
	return useContext(AuthContext)
}
