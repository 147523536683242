import React, { useState, useEffect } from 'react';
import AuthSwitch from '../AuthSwitch/AuthSwitch';
import { useAuth } from '../../util/auth';
import './header.scss';

function Header() {
  const { signOut, getUser } = useAuth();
  const [username, setUsername] = useState(''); // Use useState to initialize username

  useEffect(() => {
    getUser().then((user) => {
      if (user) {
        setUsername(user.username); // Set the username here
      }
    });
  }, [getUser]);

  return (
    <header className="header">
      <div className="header-content">
        <a className="logo" href="/">
          {username}
        </a>
        <nav>
          <a href="/">Home</a>
          <a href="/Search"> Tutoring! </a>
          <a href='/about-us'>About Us</a>
          <AuthSwitch
            ifAuth={
              <>
                <a href="/user">Profile</a>
                <a onClick={signOut} href="/">
                  Sign Out
                </a>
              </>
            }
            alt={<a href="/sign-in">Sign In</a>}
          />
        </nav>
      </div>
    </header>
  );
}

export default Header;
