import React from 'react';
import HeroSection from '../../components/hSection/HeroSection';
import Cards from '../../components/Cards/Cards';
function Home() {

  return (
    <>
      <HeroSection />
      <Cards />

      {/* Add more sections as needed */}
    </>
  );
}
export default Home;
