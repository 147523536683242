import React, { useState } from 'react'
import Input from '../../components/Input/Input.js';
import axios from 'axios';
import './signup.scss'

function SignUp() {
	const [email, setEmail] = useState('')
	const [error] = useState(null);
	const [loading, setLoading] = useState(false);


	async function handleSubmit(e) {
		e.preventDefault();
		setLoading(true);

		try {
			var code = await axios.post(`${process.env.REACT_APP_URL}api/auth/sendVerification`, {email: email});
			console.log(code);
			if(code.data){
			const encodedEmail = encodeURIComponent(email);
			const encodedCode = encodeURIComponent(code.data);
			window.location.href = `/verify/?email=${encodedEmail}&encriptCode=${encodedCode}`;
			}else{
				alert("account already exist?")
			}
		} catch (error) {
			console.log(error);
			window.location.href = '/';
		}

		setLoading(false);
	}

	return (
		<div className="signUp">
			<h1>{loading ? "Loading..." : "Sign Up"}</h1>
			<form onSubmit={handleSubmit}>
				<Input label="Email" type="email" data={email} setData={setEmail} />
				{error && <p className="error">{error}</p>}
				<p>Have an account? <a href="sign-in">Sign In</a></p>
				<button type="submit" disabled={loading}>Next</button>
			</form>
		</div>
	)
}

export default SignUp;