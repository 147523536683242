import React from 'react';
import { Button } from '../Button/Button';
import '../../App.css'
import './HeroSection.css';

function HeroSection() {
  return (
    <div className='hero-container'>
      <img src='/images/main.jpg' />
      <h1>"Two Things Made That Break And That Flight Possible"</h1>
      <p></p>
    </div>
  );
}

export default HeroSection;
