import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
import Home from "./pages/Home/Home";
import AuthContext from "./util/auth";
import Header from "./components/Header/Header";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import User from "./pages/User/User";
import AboutUs from "./pages/AboutUs/AboutUs";
import Search from "./pages/Search/Search";
import Footer from "./components/Footer/Footer";
import TutorDetails from "./pages/Search/TutorDetails";
import Verify from "./pages/SignUp/Verify";
import Password from "./pages/SignUp/Password";
import RegisterTutoring from './pages/User/RegisterTutoring';

import "./App.scss";
const router = createBrowserRouter([
	{
		path: "/",
		element: <Home />
	},
	{
		path: "/sign-in",
		element: <SignIn />
	},
	{
		path: "/sign-up",
		element: <SignUp />
	},
	{
		path: "/about-us",
		element: <AboutUs />
	},
	{
		path: "/User",
		element: <User />
	},
	{
		path: "/Search",
		element: <Search />
	},
	{
		path: "/tutor-details/:email",
		element: <TutorDetails />
	},
	{
		path: "/verify",
		element: <Verify />
	},
	{
		path: "/password",
		element: <Password />
	},
	{
		path: "/RegisterTutoring",
		element: <RegisterTutoring />
	}

]);

function App() {
	return (
		<AuthContext>
	  		<div className="App">
				<Header />
				<div>
				<RouterProvider router={router} />
				</div>
				<Footer />
	  		</div>
		</AuthContext>
	);
}

export default App;
