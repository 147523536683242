import { useAuth } from '../../util/auth';
import React, { useState, useEffect } from 'react'

function AuthSwitch(props) {
	const { getUser } = useAuth();
	const [loading, setLoading] = useState(true)
	const [authenticated, setAuthenticated] = useState(false)
	const component = props.ifAuth;
	const alt = props.alt;

	useEffect(() => {
		getUser().then((user) => {
			if(user) {
				setAuthenticated(true);
			} else {
				setAuthenticated(false);
			}
		});

		setLoading(false);
	}, [getUser])

	return (
		<>
			{
				loading ? <div>Loading...</div> : (authenticated ? component : alt)
			}
		</>
	)
}

export default AuthSwitch;