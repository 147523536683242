import React from 'react'
import "./aboutus.scss"
const aboutus = () => {


  return (
    <div className="aboutus-container">
      <h1>Why Cantaloupe?</h1>
      <p className='aboutus-text'> absolutely no reason </p>
    </div>
  );
};

export default aboutus;
