import React from 'react';
import './footer.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>&copy; {new Date().getFullYear()} the-cantaloupe.co</p>
        <p> Founder: Hing Yip Wu Email: thecantaloupe.co@gmail.com</p>
        <p> Co-Founder: Wilson Zheng</p>
      </div>
    </footer>
  );
};

export default Footer;
