import React from 'react';
import './styles.scss';

const Input = ({ label, type, data, setData }) => {
  const handleChange = (event) => {
    setData(event.target.value);
  };

  return (
    <div className="input-container">
      <input
        type={type}
        value={data}
		required={true}
		placeholder=' '
        onChange={handleChange}
        className={data ? 'has-value' : ''}
      />
      <label className={data ? 'has-value' : ''}>{label}</label>
      <span className="highlight"></span>
      <span className="bar"></span>
    </div>
  );
};

export default Input;
