import React, { useState, useEffect } from 'react'
import Input from '../../components/Input/Input.js';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../util/auth.js';
import './signup.scss'

function Password() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = decodeURIComponent(queryParams.get('email'));
    const [password, setPassword ] = useState('');
	const [confirmPassword, setConfirmPassword ] = useState('');
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const {signUp} = useAuth();

	useEffect(() => {
		if (email === 'null') {
		  window.location.href = '/';
		  return;
		}
	  })

    async function handleSubmit(e) {
        e.preventDefault();
        
		var data = {
			email:email,
			password: password
		}
		
		if(password !== confirmPassword){
			setError("Passwords do not match");
			return;
		}

		try {
			await signUp(data);
			window.location.href = '/';
		} catch (error) {
			window.location.href = '/';
		}

		setLoading(false);
    }
	return (
		<div className="signUp page">
			<h1>{loading ? "Loading..." : "Sign Up"}</h1>
			<form onSubmit={handleSubmit}>
                <p>{email}</p>
				<Input label="Create Password" type="password" data={password} setData={setPassword} />
				<Input label="Confirm Password" type="password" data={confirmPassword} setData={setConfirmPassword} />
				{error && <p className="error">{error}</p>}
				<p>Have an account? <a href="sign-in">Sign In</a></p>
				<button type="submit" disabled={loading}>Sign Up</button>
			</form>

		</div>
	)
}

export default Password;