import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../util/auth.js';
import axios from 'axios';
import "./user.scss"
import '../../App.css'
import Input from '../../components/Input/Input.js';

function User() {
  const modalRef = useRef(null);
  const [user, setUser] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  const [roles, setRoles] = useState([]); // State to store roles data
  const { getUser, signOut, getAccessToken } = useAuth();
  const [tutor, setTutor] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getData() {
      if (!await getUser()) {
        window.location.href = '/sign-in';
        return;
      }
      const user = await getUser();
      const tutor = await axios.post(`${process.env.REACT_APP_URL}api/role/getTutor`, { token: getAccessToken() });
      setTutor(tutor.data);
      setUser(user._doc);
      fetchRolesData();
    }

    getData();
  }, [getUser, getAccessToken]);

  async function getUsersForApproval() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}api/role/getAllNotTutors`);
      return response.data;
    } catch (error) {
      console.error('Error fetching users for approval', error);
      return [];
    }
  }

  async function fetchRolesData() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}api/role/get`);
      setRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles data', error);
    }
  }

  function handleModalOpen(content) {
    setModalContent(content);
    modalRef.current.showModal();
  }

  function closeModal() {
    setModalContent(null);
    modalRef.current.close();
  }
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------
  function Admin() {
    setModalContent(<AdminContent />);
    function AdminContent() {
      const [roles, setRoles] = useState([{ name: '', units: [''] }]);
    
      const handleAddUnit = (roleIndex) => {
        const newRoles = [...roles];
        newRoles[roleIndex].units.push('');
        setRoles(newRoles);
      };
    
      const handleRemoveUnit = (roleIndex, unitIndex) => {
        const newRoles = [...roles];
        newRoles[roleIndex].units.splice(unitIndex, 1);
        setRoles(newRoles);
      };
    
      const handleRoleChange = (roleIndex, value) => {
        const newRoles = [...roles];
        newRoles[roleIndex].name = value;
        setRoles(newRoles);
      };
    
      const handleUnitChange = (roleIndex, unitIndex, value) => {
        const newRoles = [...roles];
        newRoles[roleIndex].units[unitIndex] = value;
        setRoles(newRoles);
      };
    
      const handleSubmit = async () => {
        // Collect all roles and units into an array
        const token = getAccessToken();
        const allRolesAndUnits = roles.map((role) => ({
          name: role.name,
          units: role.units.filter(unit => unit.trim() !== '') // Filter out empty units
        }));
        // Do something with the collected data, e.g., send it to the server
        await axios.post(`${process.env.REACT_APP_URL}api/role/addRole`, {token: token, role: allRolesAndUnits.flatMap(role => role.name)[0], RoleUnits: allRolesAndUnits.flatMap(role => role.units)})
        window.location.href = "/user";
        closeModal();
      };
    
      return (
        <div className="modal-content">
          <h2>Create New Subject</h2>
          {roles.map((role, roleIndex) => (
            <div key={roleIndex}>
              <label>Subject</label>
              <input
                type="text"
                value={role.name}
                onChange={(e) => handleRoleChange(roleIndex, e.target.value)}
              />
    
              <button onClick={() => handleAddUnit(roleIndex)}>Add Unit</button>
    
              <div>
                <h3>Units:</h3>
                {role.units.map((unit, unitIndex) => (
                  <div key={unitIndex}>
                    <label>Unit</label>
                    <input
                      type="text"
                      value={unit}
                      onChange={(e) => handleUnitChange(roleIndex, unitIndex, e.target.value)}
                    />
                    <button onClick={() => handleRemoveUnit(roleIndex, unitIndex)}>Remove Unit</button>
                  </div>
                ))}
              </div>
            </div>
          ))}
    
          <button onClick={handleSubmit}>Submit</button>
        </div>
      );
    }   
  }
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------
  function ApproveUsersModal() {
    setModalContent(<ApproveUsersContent />);
    function ApproveUsersContent() {
      const [usersForApproval, setUsersForApproval] = useState([]);
      useEffect(() => {
        async function fetchData() {
          const users = await getUsersForApproval();
          setUsersForApproval(users);
        }
        fetchData();
      }, []);

      const handleApproveUser = async (userEmail) => {
        await axios.post(`${process.env.REACT_APP_URL}api/role/approvedUser`, {tutor: userEmail})
        console.log('Approve User:', userEmail);
      };

      return (
        <div className="modal-content">
          <h2>Approve Users</h2>
          <ul>
            {usersForApproval.map((user) => (
              <li key={user.email}>
                {user.email}
                <button onClick={() => handleApproveUser(user.email)}>Approve</button>
              </li>
            ))}
          </ul>
          <button onClick={closeModal}>Close</button>
        </div>
      );
    }
  }
  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------
  const [selectedRoleIndex, setSelectedRoleIndex] = useState(null);
  const handleRoleButtonClick = (index) => {
    setSelectedRoleIndex(index);
    handleModalOpen(<UserRoleModal role={tutor.role[index]} roleUnit={tutor.roleUnits[index]} />);
  };
  function UserRoleModal({ role , roleUnit}) {
    return (
      <div className="modal-content">
        <h2>{role}</h2>
        {roleUnit.map((unit, index) => (
        <p key={index}>{unit}<br></br></p>
      ))}
       
      </div>
    );
  }
  

  //-------------------------------------------------------------------------------------------------------------------------------------------------------------------
  function RegisterForTutoringModal() {
    window.location.href = '/RegisterTutoring';
  }
//-------------------------------------------------------------------------------------------------------------------------------------------------------------------
  

  //----------------------------------------------------------------------------------------------------------------------------------------------------------------------
  return (
    <div className="user">
      {user && user != null &&(
        <div>
      <div className="userDetails">
        {user && (
          <div className="data">
            <div>
              <br></br>
              <p>UUID: {user._id}</p>
              <p>Email: {user.email}</p>
              <p>Tutor Approval: {JSON.stringify(tutor.approved)} </p>

              <div className='roleLabel'>
                <ur>
                {tutor && tutor.role && tutor.role.length > 0 && (
  <div>
    <p>Roles:</p>
    {tutor.role.map((role, index) => (
      <div classname="subjectRole" onClick={() => handleRoleButtonClick(index)} key={index}>
      <h2>{role}</h2>
      </div>
    ))}
      </div>
)}

              </ur>
           </div>
             
            </div>
          </div>
        )}
</div>
        <div className="userActions">
          <button className="btn" onClick={RegisterForTutoringModal}>
            Register for Tutoring
          </button >
          
          <button className="btn" onClick={() => signOut()}>
            Sign Out
          </button>
          {user && user.role === 'admin' && (
            <div>
            <button className="btn" onClick={() => handleModalOpen(<Admin />)}>
              Create New Subject
            </button>
            <button className="btn" onClick={() => handleModalOpen(<ApproveUsersModal />)}>
              Approve Users
            </button>
            </div>
          )}
        </div>
      <dialog ref={modalRef} className="modal-popup">
        <button className="close-modal" onClick={closeModal}>
          X
        </button>
        {modalContent}
      </dialog>
      </div>
      )}
    </div>
  );
}

export default User;
