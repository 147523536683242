import React, { useState, useEffect} from 'react'
import Input from '../../components/Input/Input.js';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './signup.scss'

function Verify() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = decodeURIComponent(queryParams.get('email'));
    const encriptCode = queryParams.get('encriptCode');
    const [Code, setCode] = useState('');
	const [error] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		  if (email === 'null') {
			window.location.href = '/';
			return;
		  }
		})

    async function handleSubmit(e) {
        e.preventDefault();
        try {
        if(await axios.post(`${process.env.REACT_APP_URL}api/auth/verify`, {code: Code, encripNum:encriptCode})){
            const encodedEmail = encodeURIComponent(email);
			window.location.href = `/password/?email=${encodedEmail}`;
        }else{
            window.location.href = '/sign-up';
        }
			
		} catch (error) {
			console.log(error);
			window.location.href = '/';
		}

		setLoading(false);
    }
	return (
		<div className="signUp page">
			<h1>{loading ? "Loading..." : "Sign Up"}</h1>
			<form onSubmit={handleSubmit}>
                <p className='email'>Email: {email}</p>
				<p>A code has been send to {email}</p>
				<Input label="Code" type="Code" data={Code} setData={setCode} />
				{error && <p className="error">{error}</p>}
				<p>Have an account? <a href="/sign-in">Sign In</a></p>
				<p>Didn't get a code? <a href="/sign-up">Back</a></p>
				<button type="submit" disabled={loading}>Verify</button>
			</form>
		</div>
	)
}

export default Verify;