import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './search.scss';
import axios from 'axios';
import Input from '../../components/Input/Input';

// ... (imports and other code)

function Search() {
  const [search, setSearch] = useState('');
  const [tutors, setTutors] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchTutors = async () => {
      try {
        setLoading(true);
        const response = await axios.get(`${process.env.REACT_APP_URL}api/role/getAllTutors`);
        setTutors(response.data); // Assuming the data is an array of tutors
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTutors();
  }, []); // Empty dependency array means this effect runs once on component mount

  // Filter tutors based on the search input
  const filteredTutors = tutors
    ? tutors.filter(
        (tutor) =>
          tutor.role &&
          tutor.role.some((role) => role.toLowerCase().includes(search.toLowerCase()))
      )
    : null;

  return (
    <div className="search-container">
      <div className='searchbar'>
        <Input label="  search" type="Search" data={search} setData={setSearch} />
      </div>
      <p></p>

      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}

      {filteredTutors && (
        <div>
          <h2>Tutors:</h2>
          {filteredTutors.map((tutor, index) => (
            
            <Link key={index} to={{
              pathname: `/tutor-details/${encodeURIComponent(JSON.stringify(tutor))}`}}
            >
            <div key={tutor.email} className="tutor-box">
                <p>Email: {tutor.email}</p>
                <p>Subjects: {tutor.role ? tutor.role.join(', ') : 'No roles'}</p>
                <p>Time: {tutor.time}</p>
            </div>
            </Link>
            
          ))}
        </div>
      )}
    </div>
  );
}

export default Search;
