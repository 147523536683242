import React from 'react';
import './cards.css';
import CardItem from './CardItems';

function Cards() {
  return (
    <div className='cards'>
      <h1>Tutoring!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/img-9.jpg'
              text='Learn More About Us!'
              label='About Us'
              path='/about-us'
            />
            <CardItem
              src='images/img-2.jpg'
              text='Look For a Tutor!'
              label='Explore'
              path='/search'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/img-3.png'
              text='Need Help With Calculus?'
              label='Calculus'
              path='/search'
            />
            <CardItem
              src='images/img-4.jpg'
              text='Want to Learn More About Computer Science?'
              label='Computer Science'
              path='/search'
            />
            <CardItem
              src='images/img-8.jpg'
              text='Want to become a Tutor?'
              label='Register As a Tutor'
              path='/User'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
