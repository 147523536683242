import React, { useState, useEffect } from 'react';
import { useAuth } from '../../util/auth.js';
import Input from '../../components/Input/Input.js';
import axios from 'axios';
import './registertutoring.scss';

function SignUp() {
  const [time, setTime] = useState([]);
  const [description, setDescription] = useState();
  const [roles, setRoles] = useState([]);
  const [user, setUser] = useState(null);
  const { getUser, getAccessToken } = useAuth();
  const [tutor, setTutor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [addedSubjects, setAddedSubjects] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getData() {
      if (!(await getUser())) {
        window.location.href = '/sign-in';
        return;
      }
      const user = await getUser();
      const tutor = await axios.post(`${process.env.REACT_APP_URL}api/role/getTutor`, {
        token: getAccessToken(),
      });
      setTutor(tutor.data);
      setUser(user._doc);
      fetchRolesData();
    }

    getData();
  }, [getUser, getAccessToken]);

  async function fetchRolesData() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_URL}api/role/get`);
      setRoles(response.data);
    } catch (error) {
      console.error('Error fetching roles data', error);
    }
  }

  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedRoleIndex, setSelectedRoleIndex] = useState(0);

  const removeSubject = (index) => {
    setAddedSubjects((prevSubjects) => {
      const updatedSubjects = [...prevSubjects];
      updatedSubjects.splice(index, 1);
      return updatedSubjects;
    });
  };

  const handleRoleChange = (event) => {
    const selectedIndex = event.target.selectedIndex;
    setSelectedRoles([]);
    setSelectedRoleIndex(selectedIndex);
  };

  const handleUnitChange = (event) => {
    const value = event.target.value;
    setSelectedRoles((prevSelectedRoles) => {
      if (prevSelectedRoles.includes(value)) {
        return prevSelectedRoles.filter((role) => role !== value);
      } else {
        return [...prevSelectedRoles, value];
      }
    });
  };

  const handleAddSubject = () => {
    console.log(addedSubjects.length);
    if (roles[selectedRoleIndex]) {
      if (selectedRoles.length > 0) {
        const existingSubjectIndex = addedSubjects.findIndex(
          (subject) => subject.speciality === roles[selectedRoleIndex].speciality
        );

        if (existingSubjectIndex !== -1) {
          // Update existing subject with new units
          setAddedSubjects((prevSubjects) => {
            const updatedSubjects = [...prevSubjects];
            updatedSubjects[existingSubjectIndex].units = selectedRoles;
            return updatedSubjects;
          });
        } else {
          // Add a new subject
          setAddedSubjects((prevSubjects) => [
            ...prevSubjects,
            {
              speciality: roles[selectedRoleIndex].speciality,
              units: selectedRoles,
            },
          ]);
        }
      } else {
        alert('Please select at least one unit before adding a subject.');
      }
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);
    var token = getAccessToken();
    const rolesSelected = [];
    const roleUnitSelected = [];

    for(var i = 0; i<addedSubjects.length; i++){
      rolesSelected.push(addedSubjects[i].speciality)
      roleUnitSelected.push(addedSubjects[i].units)
    }

  
    var data = {
      token: token,
			role: rolesSelected,
			roleUnits: roleUnitSelected,
      time: time,
      description: description,
		}
    try {
      await axios.post(`${process.env.REACT_APP_URL}api/role/addUser`, data);
    } catch (error) {
      console.error('Error submitting form', error);
    }

    setLoading(false);
    window.location.href = '/User';
  }


  return (
    <div className="RegisterTutoring">
      <h1>{loading ? 'Loading...' : 'RegisterTutoring'}</h1>
      <form onSubmit={handleSubmit}>
      <div className="dropdown-container">
        <select className="dropdown" onChange={handleRoleChange}>
          {roles.map((role, index) => (
            <option key={index} value={role.speciality}>
              {role.speciality}
            </option>
          ))}
        </select>

        {roles[selectedRoleIndex] && (
          <div className="selected-units">
            <p>Selected Units:</p>
            <ul>
              {roles[selectedRoleIndex].units.map((unit, index) => (
                <li key={index}>
                  <input
                    type="checkbox"
                    id={`unitCheckbox_${index}`}
                    value={unit}
                    onChange={handleUnitChange}
                    checked={selectedRoles.includes(unit)}
                  />
                  <label htmlFor={`unitCheckbox_${index}`}>{unit}</label>
                </li>
              ))}
            </ul>
          </div>
        )}
        <button className="add-subject-btn" onClick={handleAddSubject}>
          Add Subject
        </button>
      </div>

      {addedSubjects.length > 0 && (
        <div className="added-subjects">
          <h2>Added Subjects:</h2>
          <ul>
            {addedSubjects.map((subject, index) => (
              <li key={index}>
                <strong>{subject.speciality}:</strong> {subject.units ? subject.units.join(', ') : ''}
                <button onClick={() => removeSubject(index)}>Remove</button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <p>Just write out the times you are avaliable, Ex: (monday-friday, 4-6pm):</p>
      <Input label="" type="" data={time} setData={setTime} />
      <p>A description of how you tutor, or anything you want the client to know about you:</p>
      <Input label="" type="" data={description} setData={setDescription} />
      
      <button className="submitButton"type="submit" disabled={loading}>Submit</button>
      </form>
    </div>
  );
}

export default SignUp;
